<template>
  <Authentication
    Title="Welcome back"
    Description="Sign in to continue"
    @togglePopup="
      $emit('togglePopup', $event);
      resetInputs();
    "
  >
    <div class="input-group" :class="{ invalid: emailError.length > 0 }">
      <input
        type="text"
        id="email"
        v-model="email"
        :class="{ hasValue: email.length > 0, invalid: emailError.length > 0 }"
      />
      <label for="email">Email Address</label>
      <div class="artificial-outline"></div>
      <p v-if="emailError.length > 0" class="error">{{ emailError }}</p>
    </div>

    <div
      class="input-group"
      id="password-group"
      :class="{ invalid: passwordError.length > 0 }"
    >
      <input
        :type="inputType(showPassword)"
        id="password"
        v-model="password"
        :class="{
          hasValue: password.length > 0,
          invalid: passwordError.length > 0
        }"
      />
      <label for="password">Password</label>
      <div class="artificial-outline"></div>
      <img
        src="@/assets/images/eye.svg"
        class="icon"
        @click="showPassword = !showPassword"
        v-if="!showPassword"
      />
      <img
        src="@/assets/images/eye-slash.svg"
        class="icon"
        @click="showPassword = !showPassword"
        v-if="showPassword"
      />
      <p v-if="passwordError.length > 0" class="error">{{ passwordError }}</p>
    </div>

    <div class="redirect">
      <!-- TODO: bright should create a link for forgot password page and verification page -->
      <!-- when the forgot password is clicked another popup should appear that allows them
            enter their email, then they would receive an email, which from there they are redirected
            to the reset password page. Nsima should create an api for that -->
      <button
        @click="
          $emit('togglePopup', 'forgot-password');
          resetInputs();
        "
      >
        Forgot password?
      </button>
      <p>
        New here?
        <button
          @click="
            $emit('togglePopup', 'register');
            resetInputs();
          "
        >
          Sign Up
        </button>
      </p>
    </div>
    <button @click="signin()" :disabled="processing">
      {{ processing ? "Logging in..." : "Login" }}
    </button>
  </Authentication>
</template>

<script>
import Authentication from "./Authentication.vue";
import authentication from "../../services/authentication";
import Validators from "../../helpers/Validators";

export default {
  name: "Login",
  components: {
    Authentication
  },
  data() {
    return {
      Rotate: false,
      showPassword: false,
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      processing: false
    };
  },
  methods: {
    inputType(value) {
      if (value === true) {
        return "text";
      } else {
        return "password";
      }
    },

    resetInputs() {
      this.email = "";
      this.password = "";
      this.showPassword = false;
    },

    signin() {
      const forEmail = new Validators();
      forEmail.required(this.email);
      forEmail.validEmail(this.email);
      this.emailError = forEmail.errors;

      const forPassword = new Validators();
      forPassword.required(this.password);
      this.passwordError = forPassword.errors;

      if (this.emailError.length === 0 && this.passwordError.length === 0) {
        this.processing = true;
        let body = {
          email: this.email,
          password: this.password
        };

        body = JSON.stringify(body);

        authentication
          .loginUser(body)
          .then(data => {
            if (data.success) {
              data.user.token = data.token;
              this.$store.dispatch("saveUser", data.user);
              this.$store.dispatch("saveSubscription", data.user.smartsubscribed);
              this.$toast.success("Your are now logged in.");
              this.resetInputs();
              this.$router.push("user/dashboard");
            } else {
              this.$toast.error(data.error);
            }
          })
          .catch(() => {
            this.$toast.error("please check your network and refresh the page");
          })
          .finally(() => {
            this.processing = false;
          });
      }
    }
  }
};
</script>

<style scoped></style>
