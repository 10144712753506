<template>
    <div class="authentication">
        <Header>
            <img src="@/assets/images/close-icon.svg" alt=""
                @click="$emit('togglePopup', ''); Rotate=!Rotate;"
                :class="{rotate: Rotate}"
            />
        </Header>
        <div class="page-content">
            <h2>{{Title}}</h2>
            <!-- <p>{{Description}}</p> -->
            <div class="form">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../Header/Header.vue';
export default {
    name: "Authentication",
    components: {
        Header
    },
    data() {
        return {
            Rotate: false,
        }
    },
    props: {
        Title: String,
        Description: String,
    }
}
</script>

<style scoped>
    .authentication {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
        min-height: 100vh;
        height: fit-content;
        display: flex;
        flex-direction: column;
        transform: translateY(-100%);
        transition: transform 1s cubic-bezier(0.42, 0, 0.78, 1.46);
    }

    .authentication img {
        cursor: pointer;
        transition: transform 0.3s;
    }

    .authentication img.rotate {
        transform: rotate(90deg);
    }

        .page-content {
        background: var(--primary-color);
        flex-grow: 1;
        color: #fff;
        font-family: "Gotham-Book";
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }

    .page-content > h2 {
        font-weight: 300;
        font-size: 30px;
        line-height: 1.0;
    }

    .page-content > p {
        font-size: 20px;
        line-height: 1.0;
        margin-top: 19px;
    }

    .page-content .form {
        margin-top: 35px;
        border-radius: 20px;
        background: #fff;
        width: 54%;
        min-width: 500px;
        max-width: 764px;
        height: fit-content;
        color: #000;
        padding: 50px 72px;
        font-family: "Gotham-Book";
    }

    @media (max-width: 991.98px) {
        .page-content .form {
            padding: 50px;
        }
    }

    @media (max-width: 767.98px) {
        .page-content .form {
            width: 80%;
            max-width: 500px;
            min-width: unset;
        }
    }

    @media (max-width: 575.98px) {
        .page-content .form {
            padding: 40px 30px;
            max-width: 500px;
            min-width: unset;
        }
    }

    @media (max-width: 374.98px) {
        .page-content .form {
            width: 90%;
        }
    }
    /* styling for form content */

    .error {
        font-size: 12px;
        color: rgb(241, 78, 50);
        font-weight: 700;
        font-style: italic;
    }
    .input-group {
        position: relative;
        width: 100%;
        height: 40px;
        margin: 0 auto 30px auto;
    }

    .input-group.invalid {
        margin-bottom: 45px;
    }

    .input-group input {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        background: transparent;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        position: relative;
        z-index: 1;
        font-size: 16px;
    }

    .input-group input:focus {
        outline: 0;
    }

    input ~ .artificial-outline {
        position: relative;
        width: 0;
        height: 2px;
        background: var(--primary-color);
        left: 50%;
        bottom: 1px;
        transition: all 0.5s;
    }

    input:focus ~ .artificial-outline {
        width: 100%;
        left: 0;
    }

    input.invalid ~ .artificial-outline {
        width: 100%;
        background: rgb(241, 78, 50);
        left: 0;
        bottom: 1px;
    }
    input.invalid {
        border-bottom: 0;
    }

    input ~ label {
        font-family: "Gotham-Book";
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: #91958F;
        position: absolute;
        z-index: 0;
        bottom: 30%;
        left: 10px;
        transition: all 0.5s;
    }

    input:focus ~ label, input.hasValue ~ label {
        left: 0;
        bottom: 100%;
    }

    .input-group .icon {
        position: absolute;
        z-index: 2;
        right: 20px;
        bottom: calc(50% - (18px / 2));
        cursor: pointer;
    }

    .redirect {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 50px 0 30px 0;
    }

    .redirect p {
        font-size: 14px;
        font-weight: 600;
    }

    .redirect > * {
        margin-bottom: 20px;
    }

    .redirect a, .redirect button {
        color: var(--primary-color);
        font-size: 14px;
        font-weight: 600;
    }

    .redirect button {
        margin-left: 0;
        display: inline-block;
        text-decoration: underline;
        background: transparent;
        border: 0;
        height: 14px;
        width: fit-content;
        cursor: pointer;
    }

    .redirect button:focus {
        background: transparent;
        outline: 0;
    }

    .redirect a:first-of-type {
        margin-right: 10px;
    }

    button {
        border: 0;
        width: 100%;
        background: var(--primary-color);
        color: #fff;
        height: 45px;
        width: 95%;
        display: block;
        margin: 0 auto;
        font-size: 22px;
        font-family: "Gotham-Book";
        cursor: pointer;
    }

    button:focus {
        outline: none;
        background: #04259c;
    }

    button:disabled {
        cursor: not-allowed;
    }


    @media (max-width: 1199.98px) {
        button {
            font-size: 18px;
        }
    }
    @media (max-width: 424.98px) {
        .input-group.invalid {
            margin-bottom: 55px;
        }
    }
</style>
