<template>
  <Header>
    <div class="nav-links" :class="{ openNav: openNav }">
      <div class="menu-header">
        <router-link to="/">
          <img src="@/assets/images/logo-mobile.svg" alt="" />
        </router-link>

        <button>
          <img
            src="@/assets/images/close-icon.svg"
            class="menu"
            alt=""
            @click="openNav = !openNav"
          />
        </button>
      </div>

      <router-link to="/">Home</router-link>

      <router-link to="/user/dashboard" v-if="isAuthenticated"
        >Dashboard</router-link
      >
      <router-link to="/faq">FAQ</router-link>
      <button
        class="sign-in"
        @click="
          $emit('togglePopup', 'signin');
          openNav = !openNav;
        "
        v-if="!isAuthenticated"
      >
        Sign in
      </button>
      <button
        class="register"
        @click="
          // trackHeader('header-button');
          $emit('togglePopup', 'register');
          openNav = !openNav;
        "
        v-if="!isAuthenticated"
      >
        Register
      </button>
      <button class="sign-in" v-if="isAuthenticated" @click="logout()">
        Logout
      </button>
    </div>
    <button @click="openNav = !openNav" class="menu">
      <img src="@/assets/images/bars.svg" alt="" />
    </button>
  </Header>
</template>

<script>
import Header from "./Header";
export default {
  name: "PageHeader",
  components: {
    Header
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    }
  },
  data() {
    return {
      openNav: false
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("clearUser");
      this.$store.dispatch("setAuthentication", false);
      // location.reload();
    },

    trackHeader(tag) {
      this.$gtag.event(tag, {
        event_category: "SMART HEADER CLICKS",
        event_label: tag,
        value: 1
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  border: 0;
  background: transparent;
}
.nav-links {
  display: flex;
  align-items: center;
}
.nav-links a,
.nav-links button {
  display: inline-block;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  font-family: Roboto;
  text-decoration: none;
  margin: 0 7px;
}
.nav-links a:hover,
.nav-links a:focus {
  color: var(--primary-color);
}
.nav-links .sign-in,
.nav-links .register {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.nav-links .sign-in {
  background: #fff;
  border: 1px solid #000000;
}
.nav-links .sign-in:hover,
.nav-links .sign-in:focus {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
  color: #000;
}
.nav-links .register {
  background: var(--primary-color);
  color: #fff;
}
.nav-links .register:hover,
.nav-links .register:focus {
  color: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
}
.menu {
  cursor: pointer;
  display: none;
  padding: 10px;
}
.menu-header {
  display: none;
}
@media (max-width: 767.98px) {
  .nav-links {
    top: 0;
    right: -250px;
    position: fixed;
    width: 250px;
    height: 100vh;
    background: #fff;
    z-index: 3;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.5s;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }
  .openNav {
    right: 0;
  }
  .menu {
    display: inline-block;
  }
  .menu-header {
    height: 70px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-links > a {
    line-height: 30px;
    text-align: left;
    display: block;
    width: 100%;
    padding: 0 30px;
    margin: 0;
  }
  .nav-links > a:hover,
  .nav-links > a:focus {
    background: var(--primary-color);
    color: #fff;
    outline: 0;
  }
  .nav-links .sign-in,
  .nav-links .register {
    display: block;
    margin: 10px 30px;
  }
}
</style>
