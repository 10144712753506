<template>
    <div>
      <div
      class="top-modal"
      v-if="termsc"
      id="tandc"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="">
          <div class="terms-parent">
            <div class="terms-holder">
            <h4>User's Terms & Condtions</h4>
            <h6>
              The following Terms and Conditions are applicable to every user of
              Shelta Application/Website. Please take out few minutes to read
              the following Terms and Conditions carefully. The use of this
              website/application indicates that you agree to be bound by the
              terms and condition, and you will follow the rules. If you do not
              agree to any of the following terms and condition then, please do
              not use this website/application. These Terms and Conditions May
              Change without any prior notice, the right to change or update the
              terms and conditions of this website is reserved by Shelta. As a
              user of the website/application, following any such amendments
              constitutes your agreement to be bound and follow the terms and
              conditions as amended. For such reasons, we motivate our users to
              read the terms and conditions carefully whenever they use this
              website/application.
            </h6>
            <div class="terms-body">
              <!-- <h5>Disclaimer</h5> -->
              <p>
                Terms and Conditions of Use. These Terms and Conditions of Use
                apply to information on www.myshelta.com and MyShelta mobile
                application including all documents, links, data, information,
                advertisements, policies, content, functions and other materials
                and services contained on the website. This Website may contain
                Content not owned by Shelta which may be subject to separate
                terms and conditions. This Website/application is provided by
                Shelta as a service to its customers for informational purposes
                and for personal use
              </p>
              <h5>Changes to these Terms:</h5>
              <p>
                This Website/Application may contain technical inaccuracies or
                typographical errors or omissions. Shelta is not responsible for
                inaccuracies, typographical errors, omissions, product
                information or advertising. Shelta reserves the right, at its
                discretion, to change, modify, add or remove all or portions of
                these Terms at any time. Your continued use of this
                Website/Application will indicate acceptance by you of such
                rules, changes or modifications.
              </p>
              <h5>Links To Third Party Sites:</h5>
              <p>
                This Website/Application may contain links to certain websites
                owned, operated or controlled by third-parties (“Third Party
                Sites”). Shelta does not control and is not responsible for the
                Content of such Third Party Sites, or any changes or updates to
                such sites. Links to Third Party Sites are provided to you only
                as a convenience, for information purposes only, and their
                inclusion does not imply any endorsement, association, approval
                or warranty by Shelta of such Third Party Sites or any of the
                Content contained therein. Access to any Third Party Sites shall
                be at your own risk.
              </p>
              <h5>Restrictions On Use Of Content:</h5>
              <p>
                Unless otherwise noted, all Content contained on this Website is
                protected by copyrights, trademarks and/or other intellectual
                properties laws. No Content may be copied, reproduced,
                republished, uploaded, posted, transmitted or distributed in any
                way, except that you may download, display and print one copy of
                the materials presented on this Website/Application on a single
                computer for your personal, non-commercial use only. The use of
                any Content from this Website/Application on any other internet,
                intranet, web or other site or computer environment is
                prohibited.
              </p>
              <h5>Additional Terms:</h5>
              <p>
                Additional terms and conditions may apply to certain portions of
                this Website. By using this Website you agree to abide and be
                bound by such additional terms and conditions as well as all
                applicable laws.
              </p>
              <h5>Trademarks:</h5>
              <p>
                The trademarks, logos and service marks displayed on this
                Website are registered trademarks of Shelta. Nothing contained
                on this Website/Application should be construed as granting any
                license or right to use any of the trademarks without the
                written permission of Shelta.
              </p>
              <h5>Communications:</h5>
              <p>
                No right of confidentiality shall apply to any inquiries or
                other communications made to SHELTA PANACEA LIMITED through this
                Website/Application and Shelta shall have no obligation to
                protect Communications from disclosure to third parties.
              </p>
              <h5>DISCLAIMER:</h5>
              <p>
                SHELTA STRIVES TO ENSURE THAT THE CONTENTS OF THIS
                WEBSITE/APPLICATION ARE ACCURATE AND RELIABLE. HOWEVER, ERRORS
                AND/OR OMISSIONS MAY SOMETIMES OCCUR. AS A RESULT, THIS
                WEBSITE/APPLICATION AND ITS CONTENTS ARE PROVIDED ON AN “AS-IS”
                BASIS. SHELTA MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND
                REGARDING THIS WEBSITE/APPLICATION, ITS CONTENT AND/OR THE
                RESULTS THAT MAY BE OBTAINED FROM USE OF THIS
                WEBSITE/APPLICATION. SHELTA HEREBY DISCLAIMS ANY AND ALL
                WARRANTIES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES
                AGAINST INFRINGEMENT AND TITLE, AND WARRANTIES THAT THIS
                WEBSITE/APPLICATION AND THE CONTENT WILL BE UNINTERRUPTED,
                TIMELY, SECURE OR FREE OF ERRORS OF VIRUSES. SHELTA IS NOT
                RESPONSIBLE FOR THE CORRECTNESS, ACCURACY, COMPLETENESS OR
                RELIABILITY OF THIS WEBSITE/APPLICATION OR ANY OF ITS CONTENT.
              </p>
              <h5>Limitation Of Liability:</h5>
              <p>
                In no event shall Shelta or any of its respective officers,
                directors, licensors, employees or agents be liable, whether in
                contract, tort, strict liability or otherwise, for any direct,
                indirect, punitive, special, incidental, consequential or
                indirect loss, injury or damages (including, without limitation,
                lost profits) arising out of or in connection with the use of
                this Website/Application or its Content.
              </p>
              <h5>Jurisdiction:</h5>
              <p>
                The Content of this Website/Application is presented solely for
                informational use in Nigeria. Shelta makes no representation
                that this Website/Application or its Content is appropriate or
                available for use in other jurisdictions. This Website is not
                intended for distribution to, or use by, any person or entity,
                or any jurisdiction or country where such distribution or use
                would be contrary to local law or regulation or would subject
                Shelta or any of its affiliates to any liability.
              </p>
              <h5>Governing Law:</h5>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of the Federal Republic of Nigeria. You agree that
                any action at law or in equity arising out of or relating to
                these Terms, this Website/Application or its Content shall be
                filed only in the state or federal courts in Nigeria and you
                hereby consent and submit to the personal jurisdiction of such
                courts for the purposes of litigating any such action, waive any
                objection to the laying of venue in such courts, and agree not
                to plead or claim in any Nigeria court that such litigation
                brought therein has been brought in an inconvenient forum. All
                disputes relating to this Website/Application or its Content
                shall be resolved individually, without resort to any form of
                class action.
              </p>
              <h5>Severability:</h5>
              <p>
                The provisions of these Terms are intended to be severable. If
                for any reason any provision of these Terms shall be unlawful,
                void, or for any reason unenforceable, then that provision shall
                be deemed severed from these Terms and shall not affect the
                validity and enforceability of any remaining provisions.
              </p>
              <h5>Entire Agreement:</h5>
              <p>
                The Terms represent the entire agreement between you and Shelta
                relating to the subject matter herein and shall not be modified
                except as provided herein or in writing signed by both parties.
                Any rights not expressly granted herein are reserved.
              </p>

              <button class="btn tc-btn" @click="termsc = false">Ok</button>
              <!-- data-dismiss="modal" -->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
// import $ from "jquery";

export default {
  components: {
  },
  data() {
    return {
        termsc: false,
    };
  },
  methods: {
    openTCModal() {
        console.log("Clicked")
        this.termsc = true;
    //   $("#tandc")
    //     .addClass("fade")
    //     .modal("show");
    },
  },
  created() {
    this.$root.$refs.TC = this;
  },
 
};
</script>

<style lang="scss" scoped>
 $primary: #0033ea;
 $white: #ffffff;
 $yellow: #ffb100;
// Terms and condtions
.tc {
  color: $yellow;
  font-weight: bold;
  cursor: pointer;
}
.top-modal {
    // margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    // display: none;
    // width: 40%;
    width: 100%;
    // max-width: 576px;
    height: 100%;
    // max-height: calc(100vh - 210px);
    overflow-y: auto;
    // height: 100%;
    // overflow: scroll;
    outline: 0;
}

.terms-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
}
.terms-holder {
  padding: 24px;
  position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  h4 {
    font-family: Lato;
    font-weight: 600;
    font-size: 20px;
  }
  h6 {
    font-family: Lato;
    text-align: left;
    line-height: 25px;
    font-size: 15px;
    margin-bottom: 20px;
  }
}
.terms-body {
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 15px;
    font-family: Lato;
    margin-bottom: 20px !important;
    line-height: 28px;
  }
  .tc-btn {
    background: $primary;
    color: $white;
    font-family: Lato;
    font-size: 16px;
    margin: auto;
    display: flex;
  }
}
// @media (min-width: 60em) {
//     .modal-dialog {
//       height: 75%;
//       margin: 5% auto;
//       max-height: 57em;
//       max-width: 66em;
//       width: 85%;
//     }
// }
// @media only screen and (min-width:576px)
// {
//   .modal-dialog {
//       max-width: 500px;
//       margin: 1.75rem auto;
//       // min-height: calc(100% - 3.5rem);
//   }
//   .modal-dialog-centered {
//     min-height: calc(100% - 3.5rem);
//   }
// }
// Terms and condtions
</style>